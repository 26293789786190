<template>
  <v-app app>
    <v-main class="text-center" style="min-height: 100vh">
      <v-row justify="center" align="center" no-gutters>
        <v-col
          v-if="!$vuetify.breakpoint.smAndDown"
          cols="12"
          sm="8"
          md="8"
          align-self="center"
        >
          <img
            style="max-height: 70vh; min-width: 80%"
            src="@/assets/svg/login.svg"
            alt="Login Assets"
          />
          <div class="text-center">
            <img
              class="sistesi-text mt-6"
              src="@/assets/svg/only-text-sistesi.svg"
              alt="Sistesi Text"
            />
            <p class="brown--text font-italic font-weight-light subtitle-1">
              {{ $t("login.section1") }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-sheet :class="{ 'grey lighten-5': !g_darkmode }">
            <v-row
              style="min-height: 100vh;"
              justify="center"
              align="center"
              no-gutters
            >
              <div style="width: 80%">
                <div class="text-center mb-6">
                  <div class="mb-5">
                    <Lottie
                      :width="150"
                      :height="150"
                      :options="lottieOptions"
                      :animCreated="handleAnimation"
                    />
                  </div>
                  <div class="mb-0">{{ $t("login.title_a") }}</div>
                  <div>
                    {{ $t("login.title_b") }} <b> {{ appName }} </b>
                  </div>
                </div>
                <p v-if="errorLogin" dense class="error--text subtitle-2">
                  {{ $t("app.login_error") }}
                </p>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="data.username"
                    label="Username"
                    name="username"
                    prepend-inner-icon="mdi-account"
                    type="text"
                    placeholder="Email/Username/NIS/NIP"
                    outlined
                    dense
                    v-on:keyup.enter="validate"
                    :rules="formValidation($t('login.username_rules'))"
                  />

                  <v-text-field
                    v-model="data.password"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="
                      formValidation(
                        {
                          a: $t('login.password_rules'),
                          b: $t('login.password_rules_minlength')
                        },
                        'password'
                      )
                    "
                    :type="showPwd ? 'text' : 'password'"
                    name="password"
                    label="Password"
                    outlined
                    autocomplete
                    dense
                    @keyup="isCapslock"
                    v-on:keyup.enter="validate"
                    @click:append="showPwd = !showPwd"
                  />

                  <v-alert
                    v-if="capsLockOn"
                    type="warning"
                    icon="mdi-alert"
                    text
                    dense
                    dark
                    class="mb-3 text-start"
                  >
                    {{ $t("app.caps_lock_on") }}
                  </v-alert>
                </v-form>
                <!-- Login with PPDB Account -->
                <v-btn
                  block
                  dark
                  class="rounded-lg"
                  depressed
                  :class="g_darkmode ? 'primary' : 'gradient-primary'"
                  :disabled="!valid"
                  :loading="waitLogin"
                  @click="validate"
                >
                  Login
                </v-btn>
                <v-row
                  no-gutters
                  class="mt-2 mb-4 caption"
                  justify="space-between"
                >
                  <v-btn
                    text
                    small
                    @click="$router.push('/homepage')"
                    class="blue--text"
                  >
                    {{ $t("login.back_to_homepage") }}
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    text
                    @click="forgotPasswordModal = true"
                  >
                    {{ $t("login.forgot_password") }}
                  </v-btn>
                </v-row>

                <fieldset id="login-with-google-container">
                  <legend>
                    <span class="mx-2">{{ $t("login.sign_in_using") }}</span>
                  </legend>
                  <v-btn
                    depressed
                    small
                    fab
                    class="mt-2"
                    @click="loginSSOHandler('google')"
                  >
                    <img :src="googleLogo" style="width: 30px; height: auto" />
                  </v-btn>
                </fieldset>

                <div class="font-weight-bold">
                  {{ g_version.frontend }} | {{ g_version.backend }}
                </div>
              </div>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>

      <LoginAuth
        :dialog="errorauth"
        :current_device="current_device"
        :devices="device_active"
        :toggle="modalErrorAuth"
        :g_darkmode="g_darkmode"
        @loginHandler="
          () =>
            this.$route.query.sign_in_method
              ? this.loginSSO(this.$route.query, 'auth')
              : this.validate()
        "
      />

      <v-dialog v-model="forgotPasswordModal" width="300" persistent>
        <v-card style="position: relative" class="text-center white">
          <!-- <v-avatar
            style="position: absolute; top: -10px; z-index: 1000"
            size="100"
            class="rounded-pill green"
          >
            <v-img contain src="@/assets/svg/forgot_password.svg" />
          </v-avatar> -->
          <v-form
            ref="formForgotPassword"
            lazy-validation
            @submit.prevent="submitForgotPassword"
          >
            <v-card-text class="pt-4">
              <h2 class="mb-2 font-weight-medium">
                {{ $t("login.reset_password_title") }}
              </h2>
              <div class="mb-3">
                {{ $t("login.reset_password_text") }}
              </div>
              <v-text-field
                v-model="email"
                :label="$t('ppdb.personal.email')"
                :rules="
                  formValidation($t('ppdb.rules.email_valid_rules'), 'email')
                "
                prepend-inner-icon="mdi-email"
                outlined
                dense
              />

              <v-row no-gutters>
                <v-btn
                  :loading="loadingForgotPassword"
                  type="submit"
                  color="primary"
                  depressed
                  block
                >
                  {{ $t("login.reset_password") }}
                </v-btn>
              </v-row>
            </v-card-text>
            <v-card-actions class="pa-0">
              <v-row
                no-gutters
                class="grey lighten-5 black--text pa-2 text-center"
                style="cursor: pointer"
                justify="center"
                @click="
                  forgotPasswordModal = false;
                  email = '';
                "
              >
                {{ $t("app.cancel") }}
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { detect } from "detect-browser";

import Lottie from "vue-lottie";
import logoAnimation from "@/assets/animation/logoAnimation.json";
import logoAnimationMistar from "@/assets/animation/logoAnimationMistar.json";
import LoginAuth from "./LoginAuth";
import { mapGetters } from "vuex";

import { req_forgot_password } from "@/api/login";

export default {
  name: "Login",
  metaInfo: {
    title: "Login",
    titleTemplate: `%s | ${process.env.VUE_APP_NAME}`
  },
  components: {
    Lottie,
    LoginAuth
  },
  data() {
    return {
      googleLogo: require("@/assets/svg/google-logo.svg"),
      lottieOptions: {
        animationData:
          process.env.VUE_APP_NAME === "Mistar"
            ? logoAnimationMistar
            : logoAnimation,
        loop: false
      },
      valid: true,
      showPwd: false,
      errorLogin: false,
      waitLogin: false,
      errorauth: false,
      current_device: { app: "web", ...detect() },
      device_active: [],
      data: {
        username: this.$route.query.username ? this.$route.query.username : "",
        password: ""
      },
      forgotPasswordModal: false,
      loadingForgotPassword: false,
      email: "",
      capsLockOn: false,
      appName: process.env.VUE_APP_NAME
    };
  },
  computed: {
    ...mapGetters(["g_version", "g_darkmode", "g_role_type"])
  },
  created() {
    this.$store.dispatch("SchoolProfile");
  },
  mounted() {
    if (
      this.$route.query &&
      this.$route.query.sign_in_method === "google" &&
      this.$route.query.access_token
    ) {
      this.loginSSO(this.$route.query);
    }
  },
  methods: {
    loginSSOHandler(type) {
      let url = "https://sso.sistesi.id";
      let origin = "https://" + window.location.hostname;

      if (this.getAppDomain() === "MISTAR") {
        url = "https://sso.mistar.co.id";
      } else if (this.getAppDomain() === "DEV") {
        // development
        url = "http://localhost:8081";
        if (origin === "localhost") {
          origin = "http://localhost:8080";
        } else {
          origin = `${window.location.hostname}:8080`;
        }
      }

      switch (type) {
        case "google":
          window.open(`${url}?origin=${origin}&sign_in_method=google`, "_self");
          break;
        default:
          break;
      }
    },
    getAppDomain() {
      const hostname = window.location.hostname.toLowerCase();
      let APPSDOMAIN = "SISTESI";
      if (hostname.includes("mistar")) {
        APPSDOMAIN = "MISTAR";
      } else if (hostname.includes("localhost")) {
        APPSDOMAIN = "DEV";
      }
      return APPSDOMAIN;
    },
    loginSSO(sso) {
      if (sso && sso.access_token && sso.sign_in_method === "google") {
        this.$store.commit("TOGGLE_FULL_LOADING");
        this.modalErrorAuth(false);

        this.$store
          .dispatch("LoginWithGoogle", sso.access_token)
          .then(res => {
            if (res.code) {
              console.log("berhasil login");
              this.$router.push("/").catch(() => {});
            } else {
              if (res.error == "NONACTIVE") {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.message,
                  color: "error"
                });
              } else {
                this.modalErrorAuth(true);
                this.device_active = res.data.detail_active;
              }
            }
            this.$store.commit("TOGGLE_FULL_LOADING");
          })
          .catch(() => {
            this.$store.commit("TOGGLE_FULL_LOADING");
          });
      }
    },
    isCapslock(event) {
      // const IS_MAC = /Mac/.test(navigator.platform);
      // const charCode = e.charCode;
      // const shiftKey = e.shiftKey;
      // if (charCode >= 97 && charCode <= 122) {
      //   this.capsLockOn = shiftKey;
      // } else if (charCode >= 65 && charCode <= 90 && !(shiftKey && IS_MAC)) {
      //   this.capsLockOn = !shiftKey;
      // }
      if (event.getModifierState("CapsLock")) {
        this.capsLockOn = true;
      } else {
        this.capsLockOn = false;
      }
    },
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    formValidation(text, type) {
      switch (type) {
        case "password":
          return [v => !!v || text.a, v => v.length >= 6 || text.b];
        default:
          return [v => !!v || text];
      }
    },
    modalErrorAuth(param) {
      this.errorauth = param;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.errorLogin = false;
        this.waitLogin = true;
        this.modalErrorAuth(false);
        this.$store
          .dispatch("Login", this.data)
          .then(res => {
            if (res.code) {
              this.$router.push("/");
            } else {
              if (res.error == "NONACTIVE") {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.message,
                  color: "error"
                });
              } else {
                this.modalErrorAuth(true);
                this.device_active = res.data.detail_active;
              }
            }
            this.waitLogin = false;
          })
          .catch(err => {
            if (err && err.status == 401) this.errorLogin = true;
            this.waitLogin = false;
          });
      }
    },
    submitForgotPassword() {
      if (this.$refs.formForgotPassword.validate()) {
        this.loadingForgotPassword = true;
        req_forgot_password({ email: this.email })
          .then(res => {
            if (res.data.code) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: this.$i18n.t("login.success_send_email"),
                color: "success"
              });
              this.forgotPasswordModal = false;
              this.email = "";
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loadingForgotPassword = false;
          })
          .catch(() => (this.loadingForgotPassword = false));
      }
    }
  }
};
</script>

<style scoped>
.left-img {
  height: 75%;
  width: 90%;
}
.sistesi-text {
  max-height: 98%;
  width: 100px;
}
a {
  text-decoration: none;
}

#login-with-google-container {
  border-color: #f5f5f5;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
  margin-bottom: 20px;
}
</style>
