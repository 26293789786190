<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card flat>
        <v-card-title class="headline mx-5" style="text-align: center">
          <div class="d-flex justify-center mb-4" style="width: 100%">
            <img
              class="mt-6"
              style="width: 100px"
              src="@/assets/svg/sistesi.svg"
              alt="Sistesi Logo"
            />
          </div>
          <div class="display-1" style="word-break: break-word !important;">
            {{ $t("app.auth_error_title") }}
          </div>
          <p class="caption mt-2" style="width: 100%">
            {{ $t("app.auth_error_subtitle") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-card class="list-auth">
            <v-card-title :class="{ white: !g_darkmode }">
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between">
                    <div class="body-2 d-flex">
                      <img
                        v-if="current_device.app === 'web'"
                        class="mr-2"
                        src="@/assets/svg/auth/gadget_windows.svg"
                        style="width: 20px; height: auto;"
                      />
                      <div>{{ current_device.os }}</div>
                    </div>
                    <div class="body-2 green--text">
                      {{ $t("app.current_device") }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text
              class="pt-4"
              :class="{ 'grey lighten-5': !g_darkmode }"
            >
              <v-row>
                <v-col cols="12" v-for="(d, i) in devices" :key="i">
                  <div class="d-flex justify-space-between mb-2">
                    <div class="body-2 d-flex">
                      <img
                        v-if="d.device === 'web'"
                        class="mr-2"
                        src="@/assets/svg/auth/gadget_windows.svg"
                        style="width: 20px; height: auto;"
                      />
                      <img
                        v-if="d.device === 'mobile'"
                        class="mr-2"
                        src="@/assets/svg/auth/gadget_android.svg"
                        style="width: 20px; height: auto;"
                      />
                      <div>{{ d.device_detail }}</div>
                    </div>
                    <div class="body-2 d-flex align-center">
                      <!-- <div class="mr-2">time</div> -->
                      <v-checkbox
                        v-model="selected"
                        :value="d.id"
                        hide-details
                        class="mt-0"
                      ></v-checkbox>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <div
            class="d-flex justify-space-between px-4 mb-4"
            style="min-width: 100%"
          >
            <v-btn color="primary" outlined @click="toggle(false)">
              {{ $t("app.back") }}
            </v-btn>
            <v-btn
              :disabled="selected.length === 0"
              :loading="loading"
              color="gradient-primary white--text"
              @click="submit"
            >
              {{ $t("app.done") }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { api_logout_device } from "@/api/login";
import i18n from "@/i18n";

export default {
  props: {
    dialog: Boolean,
    current_device: Object,
    devices: Array,
    toggle: Function,
    g_darkmode: Boolean
  },
  data() {
    return {
      selected: [],
      loading: false
    };
  },
  methods: {
    submit() {
      this.loading = true;
      api_logout_device(this.selected)
        .then(res => {
          if (res.data) {
            if (res.data.code) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: i18n.t("app.success_auth")
              });
              this.toggle(false);
              this.$emit("loginHandler");
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.list-auth {
  border: 1px solid grey;
  border-radius: 5px;
}
</style>
